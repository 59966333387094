const TREATMENTS = [
    {
        name_en: "Normal",
        name_ro: "Normal",
        source: `${process.env.REACT_APP_BACKEND_URL}/teeth/pictograme_tratamente/dinte.png`,
    },

    {
        name_en: "Crown",
        name_ro: "Coronita",
        source: `${process.env.REACT_APP_BACKEND_URL}/teeth/pictograme_tratamente/coroana.png`,
    },

    {
        name_en: "Extraction",
        name_ro: "Extractie",
        source: `${process.env.REACT_APP_BACKEND_URL}/teeth/pictograme_tratamente/extractie.png`,
    },

    {
        name_en: "Implant",
        name_ro: "Implant",
        source: `${process.env.REACT_APP_BACKEND_URL}/teeth/pictograme_tratamente/implant.png`,
    },

    {
        name_en: "Filling",
        name_ro: "Obturatie",
        source: `${process.env.REACT_APP_BACKEND_URL}/teeth/pictograme_tratamente/obturatie.png`,
    },

    {
        name_en: "Prosthesis",
        name_ro: "Proteza",
        source: `${process.env.REACT_APP_BACKEND_URL}/teeth/pictograme_tratamente/proteza.png`,
    },

    {
        name_en: "R.C.R",
        name_ro: "R.C.R",
        source: `${process.env.REACT_APP_BACKEND_URL}/teeth/pictograme_tratamente/RCR.png`,
    },

    {
        name_en: "Retreatment",
        name_ro: "Retratament",
        source: `${process.env.REACT_APP_BACKEND_URL}/teeth/pictograme_tratamente/retratament.png`,
    },

    {
        name_en: "Treatment",
        name_ro: "Tratament",
        source: `${process.env.REACT_APP_BACKEND_URL}/teeth/pictograme_tratamente/tratament.png`,
    },
];

export default TREATMENTS;